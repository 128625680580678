import tippy, { createSingleton } from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";
import { copy } from "./copy.js";

// Select all elements with the class 'icon'
const icons = document.querySelectorAll(".icon");

// Create tooltips for each icon
const tooltips = tippy(icons, {
  content: (reference) => reference.getAttribute("data-tippy-content"),
});

// Create a singleton with the tooltips
const singleton = createSingleton(tooltips, {
  theme: "my-theme",
  delay: [50, 150],
  moveTransition: "transform 0.2s ease-in-out",
  animation: "shift-away",
  hideOnClick: false,
});

tippy("#copyEmail", {
  content: "Skopírované!",
  theme: "success",
  animation: "shift-away",
  trigger: "click",
  onMount: (instance) => {
    handleCopy(instance);
  },
  ignoreAttributes: true,
});

tippy("#copyTel", {
  content: "Skopírované!",
  theme: "success",
  animation: "shift-away",
  trigger: "click",
  onMount: (instance) => {
    handleCopy(instance);
  },
  ignoreAttributes: true,
});

function handleCopy(instance) {
  singleton.setProps({
    animation: false,
  });

  singleton.hide();

  singleton.setProps({
    animation: "shift-away",
  });

  copy(instance.reference.getAttribute("data-copy"));

  setTimeout(() => {
    instance.hide();
  }, 1000);
}
