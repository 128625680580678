function copy(copyText) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(copyText).catch((err) => {
      console.error(err);
      fallbackCopyText(copyText);
    });
  } else {
    fallbackCopyText(copyText);
  }
  // alert("skopírované " + copyText);
}

function fallbackCopyText(copyText) {
  const textarea = document.createElement("textarea");
  textarea.value = copyText;
  document.body.appendChild(textarea);
  textarea.select();
  try {
    document.execCommand("copy");
  } catch (err) {
    console.error(err);
  }
  document.body.removeChild(textarea);
}

export { copy, fallbackCopyText };
